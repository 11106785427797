var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c(
      "div",
      {
        ref: "fullscreen",
        staticClass: "box1 fullscreen",
        attrs: { id: "fullscreen" }
      },
      [
        _c(
          "div",
          { staticClass: "fullScreenButtom", on: { click: _vm.fullScreen } },
          [
            _c("d2-icon", {
              staticStyle: { "font-size": "25px" },
              attrs: { name: "fa-arrows-alt" }
            })
          ],
          1
        ),
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "welcome" }, [
            _c("h1", [_vm._v("智慧养老平台可视化监管平台")])
          ])
        ]),
        _c("div", { staticClass: "left1" }, [
          _c(
            "div",
            { staticClass: "aleftboxttop", staticStyle: { height: "58%" } },
            [
              _c("h2", { staticClass: "tith2" }, [_vm._v("基本数据统计")]),
              _c(
                "div",
                { staticClass: "aleftboxttop-one-div" },
                _vm._l(_vm.countList, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "widget-inline-box text-center fl",
                      staticStyle: {
                        "margin-left": "5%",
                        "margin-right": "5%",
                        "border-radius": "5px"
                      }
                    },
                    [
                      _c("p", [_vm._v(_vm._s(item.name))]),
                      _c("h3", { staticClass: "c24c9ff" }, [
                        _vm._v(_vm._s(item.number))
                      ])
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "align-items": "center",
                    "margin-top": "5%"
                  }
                },
                _vm._l(_vm.countList2, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "widget-inline-box text-center fl",
                      staticStyle: {
                        "margin-left": "5%",
                        "margin-right": "5%",
                        "border-radius": "5px"
                      }
                    },
                    [
                      _c("p", [_vm._v(_vm._s(item.name))]),
                      _c("h3", { staticClass: "cffff00" }, [
                        _vm._v(_vm._s(item.number))
                      ])
                    ]
                  )
                }),
                0
              )
            ]
          ),
          _c("div", { staticClass: "aleftboxtbott" }, [
            _c("h2", { staticClass: "tith2" }, [_vm._v("智能设备数")]),
            _c("div", {
              staticClass: "aleftboxtbott_cont",
              attrs: { id: "aleftboxtbott" }
            })
          ])
        ]),
        _c("div", { staticClass: "mrbox" }, [
          _c(
            "div",
            { staticClass: "mrbox_topmidd", staticStyle: { width: "69%" } },
            [
              _c("div", { staticClass: "amiddboxttop" }, [
                _c("h2", { staticClass: "tith2 pt1" }, [_vm._v("报警趋势")]),
                _c("div", {
                  staticClass: "arightboxbottcont",
                  attrs: { id: "arightboxbott" }
                })
              ]),
              _c(
                "div",
                { staticClass: "amidd_bott", staticStyle: { display: "flex" } },
                [
                  _c("div", { staticClass: "amiddboxtbott1 fl" }, [
                    _c("h2", { staticClass: "tith2 pt1" }, [
                      _vm._v("智能设备类型占比")
                    ]),
                    _c("div", {
                      staticStyle: {
                        width: "91%",
                        "margin-top": "4%",
                        "margin-left": "5%",
                        height: "85%"
                      },
                      attrs: { id: "equipment-proportion" }
                    })
                  ]),
                  _c("div", { staticClass: "amiddboxtbott2 fl" }, [
                    _c("h2", { staticClass: "tith2 pt1" }, [
                      _vm._v("报警类型占比")
                    ]),
                    _c("div", {
                      staticStyle: {
                        width: "91%",
                        height: "85%",
                        "margin-top": "4%",
                        "margin-left": "5%"
                      },
                      attrs: { id: "policeProportion" }
                    })
                  ])
                ]
              )
            ]
          ),
          _c("div", { staticClass: "mrbox_top_right" }, [
            _c("div", { staticClass: "arightboxtop" }, [
              _c("h2", { staticClass: "tith2" }, [_vm._v("实时报警记录")]),
              _c(
                "div",
                {
                  staticClass: "left2_table",
                  staticStyle: { "margin-top": "4%" }
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "vue-seamless-scroll",
                        {
                          staticClass: "seamless-warp",
                          attrs: {
                            data: _vm.informationData,
                            "class-option": _vm.optionConfig
                          }
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "item" },
                            _vm._l(_vm.informationData, function(item, index) {
                              return _c("li", { key: index }, [
                                _c(
                                  "p",
                                  {
                                    staticClass: "seamless-warp-time",
                                    staticStyle: {
                                      "font-size": "12px",
                                      color: "rgba(255, 255, 255, 1)"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.time))]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass: "seamless-warp-content",
                                    staticStyle: {
                                      "font-size": "12px",
                                      color: "rgba(255, 255, 255, 1)"
                                    }
                                  },
                                  [
                                    item.personal
                                      ? _c("span", [
                                          _vm._v(_vm._s(item.personal))
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#f4c343" } },
                                      [_vm._v(_vm._s(item.alarmTypeName))]
                                    )
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass: "seamless-warp-content",
                                    staticStyle: {
                                      "font-size": "12px",
                                      color: "rgba(255, 255, 255, 1)"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.alarmLocation))]
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ]),
            _c(
              "div",
              { staticClass: "arightboxbott" },
              [
                _c("h2", { staticClass: "tith2 " }, [_vm._v("报警处理情况")]),
                _vm._l(_vm.brokenLineList, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "brokenLine-box" },
                    [
                      _c("div", { staticClass: "brokenLine-name" }, [
                        _vm._v(_vm._s(item.name))
                      ]),
                      _c("div", { staticClass: "brokenLine-number" }, [
                        _vm._v(_vm._s(item.number)),
                        _c("span", { staticClass: "brokenLine-company" }, [
                          _vm._v(_vm._s(item.company))
                        ])
                      ])
                    ]
                  )
                })
              ],
              2
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }