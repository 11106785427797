var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c("div", { staticClass: "providerOrderList-container" }, [
      _c("div", { staticClass: "filter-box" }, [
        _c(
          "div",
          { staticClass: "filter-box-name" },
          [
            _c(
              "el-descriptions",
              {
                staticClass: "margin-top",
                attrs: {
                  title: "单位信息",
                  column: 1,
                  border: "",
                  "cell-style": { textAlign: "center" }
                }
              },
              [
                _c(
                  "el-descriptions-item",
                  { attrs: { labelStyle: { width: "160px" } } },
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 单位名称 ")]),
                    _vm._v(" " + _vm._s(_vm.details.institutionName) + " ")
                  ],
                  2
                ),
                _c(
                  "el-descriptions-item",
                  { attrs: { labelStyle: { width: "160px" } } },
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 单位类型 ")]),
                    _vm._v(" " + _vm._s(_vm.details.institutionTypeName) + " ")
                  ],
                  2
                ),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 所在地区 ")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.details.provinceName + _vm.details.cityName
                        ) +
                        " "
                    )
                  ],
                  2
                ),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 详细地址 ")]),
                    _vm._v(" " + _vm._s(_vm.details.address) + " ")
                  ],
                  2
                ),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 负责人 ")]),
                    _vm._v(" " + _vm._s(_vm.details.contactor) + " ")
                  ],
                  2
                ),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 联系电话 ")]),
                    _vm._v(" " + _vm._s(_vm.details.contactorPhone) + " ")
                  ],
                  2
                ),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 创建日期 ")]),
                    _vm._v(" " + _vm._s(_vm.details.createTime) + " ")
                  ],
                  2
                ),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 有效日期 ")]),
                    _vm._v(" " + _vm._s(_vm.details.effectiveTime) + " ")
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }