import "core-js/modules/es.function.name";
import _objectSpread from "/mnt/f/project/javascriptproject/heath-alarm-provider/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from "vuex";
import util from "@/libs/util";
export default {
  computed: _objectSpread({}, mapState("d2admin/user", ["info"])),
  data: function data() {
    return {
      state: undefined,
      name: ""
    };
  },
  created: function created() {
    this.name = util.cookies.get("name");
    this.handleChange();
  },
  methods: _objectSpread(_objectSpread({}, mapActions("d2admin/account", ["logout"])), {}, {
    /**
     * @description 登出
     */
    logOff: function logOff() {
      this.logout({
        confirm: true
      });
    },
    aaa: function aaa() {
      this.$router.push({
        path: "personalCenter"
      });
    },
    Reset: function Reset() {
      this.$router.push({
        path: "resetPassword"
      });
    },
    handleChange: function handleChange(value) {
      // this.sizeSet(value)
      var timeNow = new Date();
      var hours = timeNow.getHours();

      if (hours >= 0 && hours < 8) {
        this.state = "早上好！";
      } else if (hours >= 8 && hours < 11) {
        this.state = "上午好！";
      } else if (hours >= 11 && hours <= 14) {
        this.state = "中午好！";
      } else if (hours >= 14 && hours <= 18) {
        this.state = "下午好！";
      } else if (hours > 18 && hours <= 24) {
        this.state = "晚上好！";
      }

      this.$notify({
        title: this.state,
        dangerouslyUseHTMLString: true,
        message: "欢迎您来到智慧养老服务平台",
        type: "success"
      });
    }
  })
};