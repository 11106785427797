var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c(
      "div",
      { staticClass: "providerOrderList-container" },
      [
        _c("div", { staticClass: "filter-box" }, [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("span", [_vm._v("小区名称：")]),
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.houses.districtName,
                  callback: function($$v) {
                    _vm.$set(_vm.houses, "districtName", $$v)
                  },
                  expression: "houses.districtName"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("span", [_vm._v("社区：")]),
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.houses.community,
                  callback: function($$v) {
                    _vm.$set(_vm.houses, "community", $$v)
                  },
                  expression: "houses.community"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-items" },
            [
              _c("span", [_vm._v("所在城市：")]),
              _c(
                "el-form",
                { attrs: { model: _vm.houses } },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-button": "5px" },
                      attrs: { gutter: 10 }
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-left": "0px" },
                          attrs: { span: 6 }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "150px" },
                              attrs: {
                                loading: _vm.isLoadProvinceId,
                                "loading-text": "正在加载省信息",
                                placeholder: "全部"
                              },
                              on: { change: _vm.provinceIdChange },
                              model: {
                                value: _vm.houses.provinceId,
                                callback: function($$v) {
                                  _vm.$set(_vm.houses, "provinceId", $$v)
                                },
                                expression: "houses.provinceId"
                              }
                            },
                            _vm._l(_vm.provinceIdList, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-left": "60px" },
                          attrs: { span: 6 }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "150px" },
                              attrs: { placeholder: "全部" },
                              on: { change: _vm.cityIdChange },
                              model: {
                                value: _vm.houses.cityId,
                                callback: function($$v) {
                                  _vm.$set(_vm.houses, "cityId", $$v)
                                },
                                expression: "houses.cityId"
                              }
                            },
                            _vm._l(_vm.cityIdList, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("span", [_vm._v("区域类型：")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "全部" },
                  model: {
                    value: _vm.houses.areaType,
                    callback: function($$v) {
                      _vm.$set(_vm.houses, "areaType", $$v)
                    },
                    expression: "houses.areaType"
                  }
                },
                _vm._l(_vm.types, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("span", [_vm._v("状态：")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "全部" },
                  model: {
                    value: _vm.houses.state,
                    callback: function($$v) {
                      _vm.$set(_vm.houses, "state", $$v)
                    },
                    expression: "houses.state"
                  }
                },
                _vm._l(_vm.rooms, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item", staticStyle: { width: "450px" } },
            [
              _c("span", [_vm._v("创建时间：")]),
              _c(
                "div",
                { staticClass: "block", staticStyle: { width: "30px" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.value1,
                      callback: function($$v) {
                        _vm.value1 = $$v
                      },
                      expression: "value1"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "filter-btn" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-search",
                    size: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.getDataList()
                    }
                  }
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "" },
                  on: {
                    click: function($event) {
                      return _vm.reset()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "40px" },
                  attrs: {
                    type: "primary",
                    icon: "el-icon-circle-plus-outline"
                  },
                  on: {
                    click: function($event) {
                      return _vm.newHouse()
                    }
                  }
                },
                [_vm._v("新增小区")]
              )
            ],
            1
          )
        ]),
        _c(
          "div",
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loadings,
                    expression: "loadings"
                  }
                ],
                key: _vm.tablekey,
                staticStyle: { width: "100%" },
                attrs: { data: _vm.dataList, size: "medium" }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "serialNumber", label: "小区编号" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "cityList", label: "所在城市" }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "areaNamea",
                    label: "所在区(县)",
                    width: "130"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "streetNamea",
                    label: "街道(镇)",
                    width: "130"
                  }
                }),
                _c("el-table-column", {
                  attrs: { prop: "community", label: "社区(村)", width: "130" }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "districtName",
                    label: "小区(屯)名",
                    width: "130"
                  }
                }),
                _c("el-table-column", {
                  attrs: { prop: "areaTypeName", label: "区域类型" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "address", label: "详细地址" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "createTime", label: "创建时间" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "houseNumber", label: "房屋数" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "stateName", label: "状态" }
                }),
                _c("el-table-column", {
                  attrs: { fixed: "right", label: "操作", width: "300" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "success", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.viewUser(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("查看房屋")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.edit(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          ),
                          scope.row.state === "0"
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.effectiveControl(scope.row.id)
                                    }
                                  }
                                },
                                [_vm._v("启用")]
                              )
                            : _vm._e(),
                          scope.row.state === "1"
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "danger", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.controlFailure(scope.row.id)
                                    }
                                  }
                                },
                                [_vm._v("禁用")]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total >= 0,
                  expression: "total >= 0"
                }
              ],
              attrs: {
                total: _vm.total,
                page: _vm.searchCriteria.pageNow,
                limit: _vm.searchCriteria.pageSize
              },
              on: {
                "update:page": function($event) {
                  return _vm.$set(_vm.searchCriteria, "pageNow", $event)
                },
                "update:limit": function($event) {
                  return _vm.$set(_vm.searchCriteria, "pageSize", $event)
                },
                pagination: _vm.pagingEvent
              }
            })
          ],
          1
        ),
        _c("addResidentialQuarters", {
          attrs: {
            isShowNewBuilding: _vm.isShowNewBuilding,
            initContent: _vm.CommunityInfo
          },
          on: {
            confirmNewHouse: _vm.confirmNewHouse,
            closeNewHouse: _vm.closeNewHouse
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }