var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c(
      "div",
      { staticClass: "providerOrderList-container" },
      [
        _c("div", { staticClass: "filter-box" }, [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("span", [_vm._v("用户姓名：")]),
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.houses.community,
                  callback: function($$v) {
                    _vm.$set(_vm.houses, "community", $$v)
                  },
                  expression: "houses.community"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("span", [_vm._v("手机号：")]),
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.houses.telephone,
                  callback: function($$v) {
                    _vm.$set(_vm.houses, "telephone", $$v)
                  },
                  expression: "houses.telephone"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("span", [_vm._v("用户性别：")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "全部" },
                  model: {
                    value: _vm.houses.takeEffect,
                    callback: function($$v) {
                      _vm.$set(_vm.houses, "takeEffect", $$v)
                    },
                    expression: "houses.takeEffect"
                  }
                },
                _vm._l(_vm.sexList, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("span", [_vm._v("居住房间：")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "全部" },
                  model: {
                    value: _vm.houses.room,
                    callback: function($$v) {
                      _vm.$set(_vm.houses, "room", $$v)
                    },
                    expression: "houses.room"
                  }
                },
                _vm._l(_vm.roomTypeList, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("span", [_vm._v("用户类型：")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "全部" },
                  model: {
                    value: _vm.houses.userType,
                    callback: function($$v) {
                      _vm.$set(_vm.houses, "userType", $$v)
                    },
                    expression: "houses.userType"
                  }
                },
                _vm._l(_vm.userType, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("span", [_vm._v("身份证：")]),
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.houses.idCode,
                  callback: function($$v) {
                    _vm.$set(_vm.houses, "idCode", $$v)
                  },
                  expression: "houses.idCode"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "filter-items" }, [
            _c("span", [_vm._v("所在地区：")]),
            _c(
              "div",
              {},
              [
                _c(
                  "el-form",
                  { attrs: { model: _vm.houses } },
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { "margin-button": "0px" },
                        attrs: { gutter: 10 }
                      },
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: { "margin-left": "0px" },
                            attrs: { span: 6 }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "150px" },
                                attrs: {
                                  loading: _vm.isLoadProvinceId,
                                  "loading-text": "正在加载省信息",
                                  placeholder: "全部"
                                },
                                on: { change: _vm.provinceIdChange },
                                model: {
                                  value: _vm.houses.provinceId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.houses, "provinceId", $$v)
                                  },
                                  expression: "houses.provinceId"
                                }
                              },
                              _vm._l(_vm.provinceIdList, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "margin-left": "60px" },
                            attrs: { span: 6 }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "150px" },
                                attrs: { placeholder: "全部" },
                                on: { change: _vm.cityIdChange },
                                model: {
                                  value: _vm.houses.cityId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.houses, "cityId", $$v)
                                  },
                                  expression: "houses.cityId"
                                }
                              },
                              _vm._l(_vm.cityIdList, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "filter-btn" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "",
                    plain: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.getDataList()
                    }
                  }
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "" },
                  on: {
                    click: function($event) {
                      return _vm.reset()
                    }
                  }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ]),
        _c(
          "div",
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loadings,
                    expression: "loadings"
                  }
                ],
                key: _vm.tablekey,
                staticStyle: { width: "100%" },
                attrs: { data: _vm.dataList, size: "medium" }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "id", label: "用户ID" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "userName", label: "用户姓名" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "userPhone", label: "手机号", width: "150" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "age", label: "年龄" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "sexName", label: "性别" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "userTypeName", label: "用户类型" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "idCode", label: "身份证号" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "createTime", label: "添加时间" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "city", label: "所在城市" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "userRoomName", label: "居住房间" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "guardianNumber", label: "监护人" }
                }),
                _c("el-table-column", {
                  attrs: { fixed: "right", label: "操作", width: "240" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "success", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.viewUser(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("查看详情")]
                          ),
                          scope.row.isInfo == 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-circle-plus-outline"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.goAddHealthRecords(
                                        scope.row.id
                                      )
                                    }
                                  }
                                },
                                [_vm._v("添加健康档案")]
                              )
                            : _vm._e(),
                          scope.row.isInfo == 1
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "warning",
                                    size: "small",
                                    icon: "el-icon-circle-plus-outline"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.goAddHealthRecords(
                                        scope.row.id
                                      )
                                    }
                                  }
                                },
                                [_vm._v("编辑健康档案")]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total >= 0,
                  expression: "total >= 0"
                }
              ],
              attrs: {
                total: _vm.total,
                page: _vm.pages.pageNow,
                limit: _vm.pages.pageSize
              },
              on: {
                "update:page": function($event) {
                  return _vm.$set(_vm.pages, "pageNow", $event)
                },
                "update:limit": function($event) {
                  return _vm.$set(_vm.pages, "pageSize", $event)
                },
                pagination: _vm.pagingEvent
              }
            })
          ],
          1
        ),
        _c("addUser", {
          attrs: {
            isShowAddUser: _vm.isShowAddUser,
            initContent: _vm.initContentUser
          },
          on: {
            confirmAddUser: _vm.confirmAddUser,
            cancelAddUser: _vm.cancelAddUser
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }