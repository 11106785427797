export var jdAddressCascader = {
  url: 'jdAddress/api/get/one',
  url2: 'jdAddress/api/get/two/',
  url3: 'jdAddress/api/get/three/',
  url4: 'jdAddress/api/get/four/',
  title: '地址联动',
  name: 'jdAddressCascader',
  path: 'request_ui/address/jdAddressCascader',
  router: 'jdAddressCascader'
}; // 单位
// 新增单位

export var addIns = {
  url: 'alert/institution/manage/addIns'
}; // 获取 其他单位详情

export var getInsInfo = {
  url: 'alert/institution/manage/getInsInfo'
}; // 编辑 单位

export var manageEdit = {
  url: 'alert/institution/manage/edit'
}; // 修改 单位状态

export var manageUpdateState = {
  url: 'alert/institution/manage/updateState'
}; // 重置 单位密码

export var resetPw = {
  url: 'alert/institution/manage/resetPw'
}; // 设备
// 获取 单位下的设备列表

export var getUserActiveEquipmentList = {
  url: 'alert/equipment/manage/getUserActiveEquipmentList'
}; // 移除设备

export var deleteActiveEquipment = {
  url: 'alert/equipment/manage/deleteActiveEquipment'
}; // 小区
// 新增小区

export var addCommunity = {
  url: 'alert/house/manage/addCommunity'
}; // 小区列表

export var getCommunityList = {
  url: 'alert/house/manage/getCommunityList'
}; // 小区列表分页

export var getCommunityPage = {
  url: 'alert/house/manage/getCommunityPage'
}; // 修改状态

export var updateCommunityState = {
  url: 'alert//house/manage/updateCommunityState'
}; // 获取小区详情

export var getCommunityInfo = {
  url: 'alert/house/manage/getCommunityInfo'
}; // 编辑

export var editCommunity = {
  url: 'alert/house/manage/editCommunity'
}; // 房屋
// 新增房屋

export var addHouse = {
  url: 'alert/house/manage/addHouse'
}; // 编辑 房屋

export var editHouse = {
  url: 'alert/house/manage/editHouse'
}; // 房屋列表

export var getHouseInfoList = {
  url: 'alert/house/manage/getHouseInfoList'
}; // 获取房屋下的用户列表

export var getHouseUserList = {
  url: 'alert/user/manage/getHouseUserList'
}; // 修改房屋状态

export var updateState = {
  url: 'alert/house/manage/updateState'
}; // 房屋详情

export var getHouseInfo = {
  url: 'alert/house/manage/getHouseInfo'
}; // 用户
// 获取 用户列表

export var getUserList = {
  url: 'alert/user/manage/getUserList'
}; // 新增 用户前验证用户 是否存在

export var isInstitutionUserPhone = {
  url: 'alert/user/manage/isInstitutionUser/phone/'
}; // 新增用户

export var addUser = {
  url: 'alert/user/manage/addUser'
}; // 编辑 除 监护人外的信息

export var editUser = {
  url: 'alert/user/manage/editUser'
}; // 获取 用户信息

export var getUser = {
  url: 'alert/user/manage/getUser'
}; // 移出用户

export var deleteUser = {
  url: 'alert/user/manage/deleteUser'
}; // 机构
// 树型数据

export var manageTreeList = {
  url: 'alert/institution/manage/treeList'
}; // 列表

export var manageGetList = {
  url: 'alert/institution/manage/getList'
}; // 监护人
// 获取 用户的 监护人

export var getGraByInsUserId = {
  url: 'alert/user/manage/getGraByInsUserId'
}; // 添加 监护人

export var addGuardianInfo = {
  url: 'alert/user/manage/addGuardianInfo'
}; // 移除 监护人

export var deleteGuardianInfo = {
  url: 'alert/user/manage/deleteGuardianInfo'
}; // 设备
// 列表

export var getActiveEquipmentList = {
  url: 'alert/equipment/manage/getActiveEquipmentList'
}; // 激活 设备

export var activeEquipment = {
  url: 'alert/equipment/manage/activeEquipment'
}; // 编号 查询 设备类型、名称

export var equipment = {
  url: 'alert/equipment/manage/getEquipment'
}; // 报警
// 列表

export var getAlarmLogList = {
  url: 'alert/alarm/manage/getAlarmLogList'
}; // 处理 type='' 报警

export var addAlarmHandle = {
  url: 'alert/alarm/manage/addAlarmHandle'
}; // 处理 type = 1 报警

export var pushManageHandleAlarm = {
  url: 'alert/push/manage/handleAlarm'
}; // 获取 安防 type = '' 详情

export var getAlarmLog = {
  url: 'alert/alarm/manage/getAlarmLog'
}; // 获取 安防 type = 1 详情

export var pushManageGetAlarmInfo = {
  url: 'alert/push/manage/getAlarmInfo'
}; // 获取 健康 报警

export var getAlarmsList = {
  url: 'alert/push/manage/getAlarmsList'
}; // 处理 健康 报警

export var handleAlarm = {
  url: 'alert/push/manage/handleAlarm'
}; // 常量
// 启用、禁用

export var constantGetState = {
  url: 'alert/constant/getState'
}; // 同一机构下的小区

export var getCommunity = {
  url: 'alert/constant/getCommunity'
}; // 房屋类型

export var getHouseType = {
  url: 'alert/constant/getHouseType'
}; // 用户类型

export var getUserType = {
  url: 'alert/constant/getUserType'
}; // 单位类型

export var getConstant = {
  url: 'alert/constant/getType'
}; // 性别

export var getSexState = {
  url: 'alert/constant/getSexState'
}; // 房间

export var getRoomType = {
  url: 'alert/constant/getRoomType'
}; // 获取 区域类型

export var getAreaType = {
  url: 'alert/constant/getAreaType'
}; // 获取 设备类型

export var getProductType = {
  url: 'alert/constant/getProductType'
}; // 获取 设备名称

export var getTypeProduct = {
  url: 'alert/constant/getTypeProduct'
}; // 获取 设备是否 安装房间

export var getProductTypeInfo = {
  url: 'alert/equipment/manage/getProductTypeInfo'
}; // 处理 状态

export var getHandleType = {
  url: 'alert/constant/getHandleType'
}; // 报警 类型

export var getAlarmType = {
  url: 'alert/constant/getAlarmType'
}; // 消息 类型

export var getDateType = {
  url: 'alert/constant/getDateType'
}; // 消息 查看

export var detMessage = {
  url: 'alert/login/detMessage/'
}; // 修改密码

export var manageRepw = {
  url: 'alert/system/user/setPW'
}; // 设备状态

export var getEQState = {
  url: 'alert/constant/getEQState'
}; // 获取 登录 的机构详情

export var getInfo = {
  url: 'alert/institution/manage/getInfo'
}; // 视力 常量

export var getVisionType = {
  url: 'alert/constant/getVisionType'
}; // 睡眠 类型

export var getSleepType = {
  url: 'alert/constant/getSleepType'
}; // 程度 类型

export var getDegreeType = {
  url: 'alert/constant/getDegreeType'
}; // 心血管病 类型

export var getCerebrovascularType = {
  url: 'alert/constant/getCerebrovascularType'
}; // 健康证明
// 添加 视力

export var addVision = {
  url: 'alert/health/manage/addVision'
}; // 添加 睡眠

export var addSleep = {
  url: 'alert/health/manage/addSleep'
}; // 添加 疾病

export var addDisease = {
  url: 'alert/health/manage/addDisease'
}; // 首页
// 数据 屏幕

export var getMainInterface = {
  url: 'alert/login/getMainInterface'
}; // 滚动报警数据

export var getListManage = {
  url: 'alert/alarm/manage/getList'
}; // 健康报警类型

export var getHealthAlarmType = {
  url: 'alert/constant/getHealthAlarmType'
}; // 账户管理
// 获取列表

export var getAccountList = {
  url: 'alert/system/user/getList'
}; // 添加

export var addAccount = {
  url: 'alert/system/user/add'
}; // 启用、禁用

export var accountSetStatus = {
  url: 'alert/system/user/setStatus'
}; // 重置密码

export var resetPassword = {
  url: 'alert/system/user/reSet'
};