//
//
//
//
//
//
// 引入搞得地图
import "@/plugin/amap/amap";
import AMap from "../amap/index.vue";
import { AMapManager, lazyAMapApiLoaderInstance } from "vue-amap";
export default {
  name: "index",
  components: {
    AMap: AMap
  },
  data: function data() {
    return {
      map: null
    };
  }
};