var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "aMap" },
    [
      _vm._m(0),
      _vm.isShowAmap
        ? _c(
            "el-amap",
            {
              attrs: {
                vid: "amapDemo",
                "amap-manager": _vm.amapManager,
                zoom: _vm.zoom,
                center: _vm.center,
                events: _vm.events
              }
            },
            _vm._l(_vm.a, function(item) {
              return _c("el-amap-marker", {
                key: item.id,
                attrs: {
                  position: item.offset,
                  center: item.center,
                  label: item.label
                }
              })
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info" }, [
      _c("p", { attrs: { id: "info" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }