var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c(
      "div",
      { staticClass: "providerOrderList-container" },
      [
        _c(
          "div",
          [
            _c(
              "el-descriptions",
              {
                staticClass: "margin-top",
                attrs: { title: "房屋信息", column: 2, border: "" }
              },
              [
                _c(
                  "el-descriptions-item",
                  {
                    attrs: {
                      labelStyle: { width: "100px" },
                      contentStyle: { width: "35%" }
                    }
                  },
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 所在地区 ")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.houseUserInfo.provinceNameName ||
                            _vm.houseUserInfo.provinceName
                        ) +
                        " "
                    )
                  ],
                  2
                ),
                _c(
                  "el-descriptions-item",
                  {
                    attrs: {
                      labelStyle: { width: "100px" },
                      contentStyle: { width: "35%" }
                    }
                  },
                  [
                    _c("template", { slot: "label" }, [
                      _vm._v(" 所在区（县） ")
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.houseUserInfo.streetName
                            ? _vm.houseUserInfo.areaName
                            : _vm.houseUserInfo.cityName
                        ) +
                        " "
                    )
                  ],
                  2
                ),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 街道（镇） ")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.houseUserInfo.streetName ||
                            _vm.houseUserInfo.areaName
                        ) +
                        " "
                    )
                  ],
                  2
                ),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 社区（村） ")]),
                    _vm._v(" " + _vm._s(_vm.houseUserInfo.community) + " ")
                  ],
                  2
                ),
                _c(
                  "el-descriptions-item",
                  { attrs: { span: 2 } },
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 详细地址 ")]),
                    _vm._v(" " + _vm._s(_vm.houseUserInfo.address) + " ")
                  ],
                  2
                ),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 所在小区 ")]),
                    _vm._v(" " + _vm._s(_vm.houseUserInfo.districtName) + " ")
                  ],
                  2
                ),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 楼栋号 ")]),
                    _vm._v(" " + _vm._s(_vm.houseUserInfo.floorNumber) + " ")
                  ],
                  2
                ),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 单元号 ")]),
                    _vm._v(" " + _vm._s(_vm.houseUserInfo.unitNumber) + " ")
                  ],
                  2
                ),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 门牌号 ")]),
                    _vm._v(" " + _vm._s(_vm.houseUserInfo.doorNumber) + " ")
                  ],
                  2
                ),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 房屋户型 ")]),
                    _vm._v(" " + _vm._s(_vm.houseUserInfo.houseTypeName) + " ")
                  ],
                  2
                ),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 面积 ")]),
                    _vm._v(" " + _vm._s(_vm.houseUserInfo.areas) + " ")
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-btn", staticStyle: { "margin-top": "20px" } },
          [
            _c("span", [_vm._v("用户列表")]),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "50px" },
                attrs: { type: "primary", icon: "el-icon-circle-plus-outline" },
                on: {
                  click: function($event) {
                    return _vm.newHouse()
                  }
                }
              },
              [_vm._v("添加用户")]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loadings,
                    expression: "loadings"
                  }
                ],
                key: _vm.tablekey,
                staticStyle: { width: "100%" },
                attrs: { data: _vm.dataList, size: "medium" }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "id", label: "用户ID" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "userName", label: "用户姓名" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "userPhone", label: "手机号", width: "130" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "userRoomName", label: "居住房间" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "age", label: "年龄" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "sexName", label: "性别" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "userTypeName", label: "用户类型" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "guardianNumber", label: "监护人" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "createTime", label: "添加时间" }
                }),
                _c("el-table-column", {
                  attrs: { fixed: "right", label: "操作", width: "300" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.viewUser(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("查看详情")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "success", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.edit(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "danger", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.effectiveControl(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("移除")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total >= 0,
                  expression: "total >= 0"
                }
              ],
              attrs: {
                total: _vm.total,
                page: _vm.searchCriteria.currentPage,
                limit: _vm.searchCriteria.pageSize
              },
              on: {
                "update:page": function($event) {
                  return _vm.$set(_vm.searchCriteria, "currentPage", $event)
                },
                "update:limit": function($event) {
                  return _vm.$set(_vm.searchCriteria, "pageSize", $event)
                },
                pagination: _vm.pagingEvent
              }
            })
          ],
          1
        ),
        _c("addUser", {
          attrs: {
            isShowAddUser: _vm.isShowAddUser,
            initContent: _vm.CommunityInfo,
            currentId: _vm.currentId
          },
          on: {
            confirmAddUser: _vm.confirmAddUser,
            cancelAddUser: _vm.cancelAddUser
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }