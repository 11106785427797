import "core-js/modules/es.array.concat";
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);
/**
 * 在主框架内显示
 */


var frameIn = [{
  path: '/',
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: [// 首页
  // {
  //   path: 'index',
  //   name: 'index',
  //   meta: {
  //     auth: true
  //   },
  //   component: _import('system/index')
  // },
  {
    path: 'index',
    name: 'index',
    meta: {
      auth: true
    },
    component: _import('homePage/index')
  }, {
    path: 'housingManagement',
    name: 'housingManagement',
    meta: {
      title: '房屋管理',
      auth: true
    },
    component: _import('housingManagement/index')
  }, {
    path: 'celLlist',
    name: 'celLlist',
    meta: {
      title: '小区列表',
      auth: true
    },
    component: _import('housingManagement/celLlist/index')
  }, {
    path: 'seeHousingManagement',
    name: 'seeHousingManagement',
    meta: {
      title: '房屋信息',
      auth: true
    },
    component: _import('housingManagement/seeHousingManagement/index')
  }, {
    path: 'userManagement',
    name: 'userManagement',
    meta: {
      title: '用户详情',
      auth: true
    },
    component: _import('userManagement/index')
  }, {
    path: 'deviceManagement',
    name: 'deviceManagement',
    meta: {
      title: '设备管理',
      auth: true
    },
    component: _import('deviceManagement/index')
  }, {
    path: 'deviceManagementUser',
    name: 'deviceManagementUser',
    meta: {
      title: '用户详情',
      auth: true
    },
    component: _import('deviceManagement/deviceManagementUser/index')
  }, {
    path: 'alarmRecord',
    name: 'alarmRecord',
    meta: {
      title: '安防报警',
      auth: true
    },
    component: _import('alarmRecord/index')
  }, {
    path: 'healthAlarm',
    name: 'healthAlarm',
    meta: {
      title: '健康报警',
      auth: true
    },
    component: _import('healthAlarm/index')
  }, {
    path: 'amapSee',
    name: 'amapSee',
    meta: {
      title: '报警详情',
      auth: true
    },
    component: _import('alarmRecord/amapSee/index')
  }, {
    path: 'unitList',
    name: 'unitList',
    meta: {
      title: '单位列表',
      auth: true
    },
    component: _import('unitManagement/unitList/index')
  }, {
    path: 'alarmAmap',
    name: 'alarmAmap',
    meta: {
      title: '报警地图',
      auth: true
    },
    component: _import('alarmRecord/alarmAmap/index')
  }, {
    path: 'userManagementList',
    name: 'userManagementList',
    meta: {
      title: '用户列表',
      auth: true
    },
    component: _import('userManagement/userManagementList/index')
  }, // 健康 证明
  {
    path: 'healthRecordsAdd',
    name: 'healthRecordsAdd',
    meta: {
      title: '健康档案',
      auth: true
    },
    component: _import('userManagement/healthRecords/add')
  }, {
    path: 'personalCenter',
    name: 'personalCenter',
    meta: {
      title: '信息中心',
      auth: true
    },
    component: _import('personalCenter/index')
  }, {
    path: 'resetPassword',
    name: 'resetPassword',
    meta: {
      title: '修改密码',
      auth: true
    },
    component: _import('resetPassword/index')
  }, {
    path: 'accountList',
    name: 'accountList',
    meta: {
      title: '账户列表',
      auth: true
    },
    component: _import('account/accountList')
  }, // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  }, // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  }]
}];
/**
 * 在主框架之外显示
 */

var frameOut = [// 登录
{
  path: '/login/:id',
  name: 'login',
  component: _import('system/login')
}, {
  path: '/quick',
  name: 'quick',
  component: _import('system/quick/index.vue')
}];
/**
 * 错误页面
 */

var errorPage = [{
  path: '*',
  name: '404',
  component: _import('system/error/404')
}]; // 导出需要显示菜单的

export var frameInRoutes = frameIn; // 重新组织后导出

export default [].concat(frameIn, frameOut, errorPage);