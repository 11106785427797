import "core-js/modules/es.object.to-string";
import Vue from 'vue';
import d2Container from './d2-container'; // 注意 有些组件使用异步加载会有影响

Vue.component('d2-container', d2Container);
Vue.component('d2-icon', function () {
  return import('./d2-icon');
});
Vue.component('d2-icon-svg', function () {
  return import('./d2-icon-svg/index.vue');
}); // 分页组件

Vue.component('pagination', function () {
  return import('./common/pagination/index.vue');
}); // 新建房屋

Vue.component('newBuilding', function () {
  return import('./house/newBuilding/index.vue');
}); // 添加设备

Vue.component('addDevice', function () {
  return import('./house/addDevice/index.vue');
}); // 添加用户

Vue.component('addUser', function () {
  return import('./house/addUser/index.vue');
}); // 添加单位

Vue.component('addUnit', function () {
  return import('./house/addUnit/index.vue');
}); // 选择上级单位弹框

Vue.component('addUnitUp', function () {
  return import('./house/addUnit/addUnitUp/index.vue');
}); // 新增小区

Vue.component('addResidentialQuarters', function () {
  return import('./house/addResidentialQuarters/index.vue');
}); // 处理信息

Vue.component('treatment', function () {
  return import('./house/treatment/index.vue');
}); // 查看报警记录信息

Vue.component('viewAlarms', function () {
  return import('./house/viewAlarms/index.vue');
}); // 重置单位密码

Vue.component('resetPw', function () {
  return import('./house/resetPw/index.vue');
}); // 新增监护人

Vue.component('addGuardian', function () {
  return import('./house/addGuardian/index.vue');
}); // 新增账号

Vue.component('addAccount', function () {
  return import('./house/addAccount/index.vue');
});