import "core-js/modules/es.array.concat";
import "core-js/modules/es.object.to-string";
import axios from 'axios';
import Adapter from 'axios-mock-adapter';
import { get } from 'lodash';
import util from '@/libs/util';
import { errorLog, errorCreate } from './tools';
import store from '@/store/index';
/**
 * @description 创建请求实例
 */

function createService() {
  // 创建一个 axios 实例
  var service = axios.create(); // 请求拦截

  service.interceptors.request.use(function (config) {
    return config;
  }, function (error) {
    // 发送失败
    console.log(error);
    return Promise.reject(error);
  }); // 响应拦截

  service.interceptors.response.use(function (response) {
    // dataAxios 是 axios 返回数据中的 data
    var dataAxios = response.data; // 这个状态码是和后端约定的

    var code = dataAxios.code; // 根据 code 进行判断

    console.log(code);

    if (code === undefined) {
      // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
      return dataAxios;
    } else {
      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      switch (code) {
        // case 0:
        //   // [ 示例 ] code === 0 代表没有错误
        //   return dataAxios.data
        case 1:
          // [ 示例 ] code === 0 代表没有错误
          return dataAxios.data;

        case 2:
          // [ 示例 ] code === 2 代表没有错误
          return dataAxios;

        case 'xxx':
          // [ 示例 ] 其它和后台约定的 code
          errorCreate("[ code: xxx ] ".concat(dataAxios.msg, ": ").concat(response.config.url));
          break;

        default:
          // 不是正确的 code
          // errorCreate(`${dataAxios.msg}: ${response.config.url}`)
          errorCreate("".concat(dataAxios.msg));
          break;
      }
    }
  }, function (error) {
    var status = get(error, 'response.status');

    switch (status) {
      case 400:
        error.message = '请求错误';
        break;

      case 401:
        store.dispatch('d2admin/account/logout', {
          confirm: false
        });
        error.message = '未授权，请登录';
        break;

      case 403:
        error.message = '拒绝访问';
        break;

      case 404:
        error.message = "\u8BF7\u6C42\u5730\u5740\u51FA\u9519: ".concat(error.response.config.url);
        break;

      case 408:
        error.message = '请求超时';
        break;

      case 500:
        error.message = '服务器内部错误';
        break;

      case 501:
        error.message = '服务未实现';
        break;

      case 502:
        error.message = '网关错误';
        break;

      case 503:
        error.message = '服务不可用';
        break;

      case 504:
        error.message = '网关超时';
        break;

      case 505:
        error.message = 'HTTP版本不受支持';
        break;

      default:
        break;
    }

    errorLog(error);
    return Promise.reject(error);
  });
  return service;
}
/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */
// function createRequestFunction (service) {
//   return function (config) {
//     const token = util.cookies.get('token')
//     const configDefault = {
//       headers: {
//         Authorization: token,
//         'Content-Type': get(config, 'headers.Content-Type', 'application/json')
//       },
//       timeout: 5000,
//       baseURL: process.env.VUE_APP_API,
//       data: {}
//     }
//     return service(Object.assign(configDefault, config))
//   }
// }


function createRequestFunction(service) {
  return function (config) {
    // console.log(config.method,'method')
    var token = util.cookies.get('token');
    var configDefault = {
      headers: {
        token: token || '',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': config.method === 'GET' ? 'application/x-www-form-urlencoded' : 'application/json;charset=utf-8'
      },
      timeout: 10000,
      baseURL: process.env.VUE_APP_API,
      data: {}
    };
    return service(Object.assign(configDefault, config));
  };
} // 用于真实网络请求的实例和请求方法


export var service = createService();
export var request = createRequestFunction(service); // 用于模拟网络请求的实例和请求方法

export var serviceForMock = createService();
export var requestForMock = createRequestFunction(serviceForMock); // 网络请求数据模拟工具

export var mock = new Adapter(serviceForMock);