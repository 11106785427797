import "core-js/modules/es.array.map";
import _objectSpread from "/mnt/f/project/javascriptproject/heath-alarm-provider/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { uniqueId } from 'lodash';
/**
 * @description 给菜单数据补充上 path 字段
 * @description https://github.com/d2-projects/d2-admin/issues/209
 * @param {Array} menu 原始的菜单数据
 */

function supplementPath(menu) {
  return menu.map(function (e) {
    return _objectSpread(_objectSpread({}, e), {}, {
      path: e.path || uniqueId('d2-menu-empty-')
    }, e.children ? {
      children: supplementPath(e.children)
    } : {});
  });
}

export var menuHeader = supplementPath([{
  path: '/index',
  title: '首页',
  icon: 'home'
}, {
  path: '/housingManagement',
  title: '房屋管理'
}, {
  title: '页面',
  icon: 'folder-o',
  children: [{
    path: '/page1',
    title: '页面 1'
  }, {
    path: '/page2',
    title: '页面 2'
  }, {
    path: '/page3',
    title: '页面 3'
  }]
}]);
export var menuAside = supplementPath([{
  path: '/index',
  title: '首页',
  icon: 'home'
}, {
  title: '单位管理',
  icon: 'folder-o',
  children: [{
    path: '/unitList',
    title: '单位列表'
  }]
}, {
  title: '社区管理',
  icon: 'folder-o',
  children: [{
    path: '/celLlist',
    title: '小区列表'
  }, {
    path: '/housingManagement',
    title: '房屋列表'
  }]
}, {
  title: '用户管理',
  icon: 'folder-o',
  children: [{
    path: '/userManagementList',
    title: '用户列表'
  }]
}, {
  title: '设备管理',
  icon: 'folder-o',
  children: [{
    path: '/deviceManagement',
    title: '设备列表'
  }]
}, {
  title: '报警管理',
  icon: 'folder-o',
  children: [{
    path: '/alarmRecord',
    title: '安防报警'
  }, {
    path: '/healthAlarm',
    title: '健康报警'
  }]
}, {
  title: '账户管理',
  icon: 'folder-o',
  children: [{
    path: '/accountList',
    title: '账户列表'
  }]
}]);