import "core-js/modules/es.array.slice";
var state = [{
  value: 'z',
  label: '全部'
}, {
  value: 1,
  label: '启用'
}, {
  value: 2,
  label: '禁用'
}];
var houseType = [{
  value: '',
  label: '全部'
}, {
  value: 1,
  label: '大开间'
}, {
  value: 2,
  label: '一居室'
}, {
  value: 3,
  label: '二居室'
}, {
  value: 4,
  label: '三居室'
}, {
  value: 5,
  label: '四居室'
}, {
  value: 6,
  label: '五居室'
}, {
  value: 7,
  label: '其他'
}];
var room = [{
  value: '',
  label: '全部'
}, {
  value: 1,
  label: '主卧'
}, {
  value: 2,
  label: '次卧'
}, {
  value: 3,
  label: '书房'
}, {
  value: 4,
  label: '客房'
}, {
  value: 5,
  label: '客厅'
}, {
  value: 6,
  label: '阳台'
}]; // 用户类型

var customerType = [{
  value: '',
  label: '全部'
}, {
  value: 1,
  label: '老人'
}, {
  value: 2,
  label: '儿童'
}, {
  value: 3,
  label: '障碍人士'
}]; // 性别

var gender = [{
  value: '',
  label: '全部'
}, {
  value: 1,
  label: '男'
}, {
  value: 2,
  label: '女'
}]; // 设备类型

var equipmentType = [{
  value: '',
  label: '全部',
  room: ''
}, {
  value: 1,
  label: 'SOS',
  room: 0
}, {
  value: 2,
  label: '烟感',
  room: 1
}, {
  value: 3,
  label: '水感'
}, {
  value: 4,
  label: '气感'
}, {
  value: 5,
  label: '门磁'
}, {
  value: 6,
  label: '夜灯'
}, {
  value: 7,
  label: '手表'
}, {
  value: 8,
  label: '手环'
}, {
  value: 9,
  label: '胸卡'
}, {
  value: 10,
  label: '拐杖'
}, {
  value: 11,
  label: '床垫'
}]; // SOS

var sosType = [{
  value: '',
  label: '全部'
}, {
  value: 1,
  label: 'SOS报警器NB版'
}, {
  value: 2,
  label: 'SOS报警器4G版'
}, {
  value: 3,
  label: 'SOS报警器433版'
}]; // 烟感

var smokeSensation = [{
  value: '',
  label: '全部'
}, {
  value: 1,
  label: '烟感报警器NB版'
}, {
  value: 2,
  label: '烟感报警器4G版'
}, {
  value: 3,
  label: '烟感报警器433版'
}]; // 安装房间

var installationRoom = [{
  value: '',
  label: '全部'
}, {
  value: 1,
  label: '主卧'
}, {
  value: 2,
  label: '次卧'
}, {
  value: 3,
  label: '书房'
}, {
  value: 4,
  label: '客房'
}, {
  value: 5,
  label: '客厅'
}, {
  value: 6,
  label: '餐厅'
}, {
  value: 7,
  label: '阳台'
}, {
  value: 8,
  label: '卫生间'
}]; // 设备状态

var equipmentStatus = [{
  value: '',
  label: '全部'
}, {
  value: 1,
  label: '在线'
}, {
  value: 2,
  label: '离线'
}]; // 报警类型

var alarmType = [{
  value: '',
  label: '全部'
}, {
  value: 1,
  label: '烟感报警'
}, {
  value: 2,
  label: 'SOS报警'
}]; // 眼科情况 

var visionTypeList = [{
  label: "近视",
  value: 1
}, {
  label: "散光",
  value: 2
}, {
  label: "老花",
  value: 3
}, {
  label: "弱视",
  value: 4
}, {
  label: "其他",
  value: 5
}]; // 基础病

var cerebrovascularTypeList = [{
  label: "冠心病",
  value: 1
}, {
  label: "心力衰竭",
  value: 2
}, {
  label: "高血压",
  value: 3
}, {
  label: "高血脂",
  value: 4
}, {
  label: "心血管病",
  value: 5
}, {
  label: "心律失常",
  value: 6
}, {
  label: "蛛网膜下腔出血",
  value: 7
}, {
  label: "高血压性脑出血",
  value: 8
}, {
  label: "脑梗死",
  value: 9
}, {
  label: "颅内动脉瘤",
  value: 10
}, {
  label: "颅内异常血管",
  value: 11
}, {
  label: "其他",
  value: 2
}];

function GetAge(identityCard) {
  if (!identityCard) {
    var age = '';
    return age;
  }

  var len = (identityCard + "").length;

  if (len == 0) {
    return 0;
  } else {
    if (len != 15 && len != 18) //身份证号码只能为15位或18位其它不合法
      {
        return 0;
      }
  }

  var strBirthday = "";

  if (len == 18) //处理18位的身份证号码从号码中得到生日和性别代码
    {
      strBirthday = identityCard.substr(6, 4) + "/" + identityCard.substr(10, 2) + "/" + identityCard.substr(12, 2);
    }

  if (len == 15) {
    strBirthday = "19" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
  } //时间字符串里，必须是“/”


  var birthDate = new Date(strBirthday);
  var nowDateTime = new Date();
  var age = nowDateTime.getFullYear() - birthDate.getFullYear(); //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1

  if (nowDateTime.getMonth() < birthDate.getMonth() || nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate()) {
    age--;
  }

  return age;
}

function GetSex(idCard) {
  if (!idCard) {
    return '';
  }

  return parseInt(idCard.slice(-2, -1)) % 2 == 1 ? '男' : '女';
}

function GetSexNumber(idCard) {
  if (!idCard) {
    return '';
  }

  return parseInt(idCard.slice(-2, -1)) % 2 == 1 ? 1 : 0;
}

function isNumber(value) {
  return isNaN(value);
}

export { state, houseType, room, customerType, gender, equipmentType, sosType, smokeSensation, installationRoom, equipmentStatus, alarmType, GetAge, GetSex, GetSexNumber, visionTypeList, cerebrovascularTypeList, isNumber };