import "core-js/modules/es.array.find";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import _objectSpread from "/mnt/f/project/javascriptproject/heath-alarm-provider/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from "vuex";
import Sortable from "sortablejs";
export default {
  components: {
    D2Contextmenu: function D2Contextmenu() {
      return import("../contextmenu");
    },
    D2ContextmenuList: function D2ContextmenuList() {
      return import("../contextmenu/components/contentmenuList");
    }
  },
  data: function data() {
    return {
      contextmenuFlag: false,
      contentmenuX: 0,
      contentmenuY: 0,
      contextmenuListIndex: [{
        icon: "times-circle",
        title: "关闭全部",
        value: "all"
      }],
      contextmenuList: [{
        icon: "refresh",
        title: "刷新",
        value: "refresh"
      }, {
        icon: "arrow-left",
        title: "关闭左侧",
        value: "left"
      }, {
        icon: "arrow-right",
        title: "关闭右侧",
        value: "right"
      }, {
        icon: "times",
        title: "关闭其它",
        value: "other"
      }, {
        icon: "times-circle",
        title: "关闭全部",
        value: "all"
      }],
      tagName: "/index"
    };
  },
  computed: _objectSpread({}, mapState("d2admin/page", ["opened", "current"])),
  methods: _objectSpread(_objectSpread({}, mapActions("d2admin/page", ["close", "closeLeft", "closeRight", "closeOther", "closeAll", "openedSort"])), {}, {
    /**
     * @description 计算某个标签页是否可关闭
     * @param {Object} page 其中一个标签页
     */
    isTabClosable: function isTabClosable(page) {
      return page.name !== "index";
    },

    /**
     * @description 右键菜单功能点击
     * @param {Object} event 事件
     */
    handleContextmenu: function handleContextmenu(event) {
      var target = event.target; // fix https://github.com/d2-projects/d2-admin/issues/54

      var flag = false;
      if (target.className.indexOf("el-tabs__item") > -1) flag = true;else if (target.parentNode.className.indexOf("el-tabs__item") > -1) {
        target = target.parentNode;
        flag = true;
      }

      if (flag) {
        event.preventDefault();
        event.stopPropagation();
        this.contentmenuX = event.clientX;
        this.contentmenuY = event.clientY;
        this.tagName = target.getAttribute("aria-controls").slice(5);
        this.contextmenuFlag = true;
      }
    },

    /**
     * @description 右键菜单的 row-click 事件
     * @param {String} command 事件类型
     */
    contextmenuClick: function contextmenuClick(command) {
      this.handleControlItemClick(command, this.tagName);
    },

    /**
     * @description 接收点击关闭控制上选项的事件
     * @param {String} command 事件类型
     * @param {String} tagName tab 名称
     */
    handleControlItemClick: function handleControlItemClick(command) {
      var tagName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      if (tagName) this.contextmenuFlag = false;
      var params = {
        pageSelect: tagName
      };

      switch (command) {
        case "refresh":
          this.$router.push({
            name: "refresh"
          });
          break;

        case "left":
          this.closeLeft(params);
          break;

        case "right":
          this.closeRight(params);
          break;

        case "other":
          this.closeOther(params);
          break;

        case "all":
          this.closeAll();
          break;

        default:
          this.$message.error("无效的操作");
          break;
      }
    },

    /**
     * @description 接收点击 tab 标签的事件
     * @param {object} tab 标签
     * @param {object} event 事件
     */
    handleClick: function handleClick(tab, event) {
      // 找到点击的页面在 tag 列表里是哪个
      var page = this.opened.find(function (page) {
        return page.fullPath === tab.name;
      });

      if (page) {
        var name = page.name,
            params = page.params,
            query = page.query;
        this.$router.push({
          name: name,
          params: params,
          query: query
        });
      }
    },

    /**
     * @description 点击 tab 上的删除按钮触发这里
     * @param {String} tagName tab 名称
     */
    handleTabRemove: function handleTabRemove(tagName) {
      console.log(tagName);
      this.close({
        tagName: tagName
      });
    }
  }),
  mounted: function mounted() {
    var _this = this;

    var el = document.querySelectorAll(".d2-multiple-page-sort .el-tabs__nav")[0];
    Sortable.create(el, {
      onEnd: function onEnd(evt) {
        var oldIndex = evt.oldIndex,
            newIndex = evt.newIndex;

        _this.openedSort({
          oldIndex: oldIndex,
          newIndex: newIndex
        });
      }
    });
  }
};