import { find, assign } from 'lodash';
var users = [{
  username: 'admin',
  password: 'admin',
  uuid: 'admin-uuid',
  name: 'Admin'
}, {
  username: 'editor',
  password: 'editor',
  uuid: 'editor-uuid',
  name: 'Editor'
}, {
  username: 'user1',
  password: 'user1',
  uuid: 'user1-uuid',
  name: 'User1'
}];
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    /**
     * @description 登录
     * @param {Object} data 登录携带的信息
     */
    SYS_USER_LOGIN: function SYS_USER_LOGIN() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      // 模拟数据
      // mock
      //   .onAny('/login')
      //   .reply(config => {
      //     const user = find(users, tools.parse(config.data))
      //     console.log(user)
      //     return user
      //       ? tools.responseSuccess(assign({}, user, { token: faker.random.uuid() }))
      //       : tools.responseError({}, '账号或密码不正确')
      //   })
      // 接口请求
      console.log("\u8BF7\u6C42\u53C2\u6570 ".concat(JSON.stringify(data, null, 2)));
      return request({
        url: '/alert/login/loginIns',
        method: 'post',
        data: data
      });
    }
  };
});