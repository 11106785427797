export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET: function GET(url, params) {
      return request({
        url: url,
        method: 'GET',
        params: params
      });
    },
    POST: function POST(url, data) {
      return request({
        url: url,
        method: 'POST',
        data: data
      });
    }
  };
});