var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "providerOrderList-container" }, [
            _c("div", { staticClass: "settle-box" }, [
              _c("div", { staticClass: "bor", attrs: { border: "" } }, [
                _c("span", { staticClass: "title-header" }, [
                  _vm._v("用户信息")
                ])
              ]),
              _c("div", { staticClass: "bor", attrs: { border: "" } }, [
                _c("span", { staticClass: "bor-title" }, [_vm._v("用户姓名")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.details.userName))
                ]),
                _c("span", { staticClass: "bor-title" }, [_vm._v("手机号")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.details.userPhone))
                ])
              ]),
              _c("div", { staticClass: "bor", attrs: { border: "" } }, [
                _c("span", { staticClass: "bor-title" }, [_vm._v("用户类型")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.details.userTypeName))
                ]),
                _c("span", { staticClass: "bor-title" }, [_vm._v("身份证号")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.details.idCode))
                ])
              ]),
              _c("div", { staticClass: "bor" }, [
                _c("span", { staticClass: "bor-title" }, [_vm._v("性别")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.details.sexName))
                ]),
                _c("span", { staticClass: "bor-title" }, [_vm._v("年龄")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.details.age))
                ])
              ]),
              _c("div", { staticClass: "bor" }, [
                _c("span", { staticClass: "bor-title" }, [_vm._v("居住房间")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.details.userRoomName))
                ]),
                _c("span", { staticClass: "bor-title" }, [_vm._v("监护人数")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.details.guardianNumber))
                ])
              ])
            ]),
            _c("div", { staticClass: "settle-box" }, [
              _c("div", { staticClass: "bor", attrs: { border: "" } }, [
                _c("span", { staticClass: "title-header" }, [
                  _vm._v("房屋信息")
                ])
              ]),
              _c("div", { staticClass: "bor", attrs: { border: "" } }, [
                _c("span", { staticClass: "bor-title" }, [_vm._v("所在地区")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(
                    _vm._s(
                      _vm.houseInfoList.provinceNameName
                        ? _vm.houseInfoList.provinceNameName
                        : _vm.houseInfoList.provinceName
                    )
                  )
                ]),
                _c("span", { staticClass: "bor-title" }, [
                  _vm._v("所在区（县）")
                ]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(
                    _vm._s(
                      _vm.houseInfoList.provinceNameName
                        ? _vm.houseInfoList.areaName
                        : _vm.houseInfoList.cityName
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "bor", attrs: { border: "" } }, [
                _c("span", { staticClass: "bor-title" }, [
                  _vm._v(" 街道（镇）")
                ]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(
                    _vm._s(
                      _vm.houseInfoList.provinceNameName
                        ? _vm.houseInfoList.streetName
                        : _vm.houseInfoList.areaName
                    )
                  )
                ]),
                _c("span", { staticClass: "bor-title" }, [
                  _vm._v(" 社区（村）")
                ]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.houseInfoList.community))
                ])
              ]),
              _c("div", { staticClass: "bor", attrs: { border: "" } }, [
                _c("span", { staticClass: "bor-title" }, [_vm._v("详细地址")]),
                _c("span", { staticClass: "bor-content-all" }, [
                  _vm._v(_vm._s(_vm.houseInfoList.address))
                ])
              ]),
              _c("div", { staticClass: "bor" }, [
                _c("span", { staticClass: "bor-title" }, [_vm._v("所在小区")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.houseInfoList.districtName))
                ]),
                _c("span", { staticClass: "bor-title" }, [_vm._v("楼栋号")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.houseInfoList.floorNumber))
                ])
              ]),
              _c("div", { staticClass: "bor" }, [
                _c("span", { staticClass: "bor-title" }, [_vm._v("单元号")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.houseInfoList.unitNumber))
                ]),
                _c("span", { staticClass: "bor-title" }, [_vm._v("门牌号")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.houseInfoList.doorNumber))
                ])
              ]),
              _c("div", { staticClass: "bor" }, [
                _c("span", { staticClass: "bor-title" }, [_vm._v("房屋户型")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.houseInfoList.houseTypeName))
                ]),
                _c("span", { staticClass: "bor-title" }, [_vm._v("面积")]),
                _vm.houseInfoList.areas
                  ? _c("span", { staticClass: "bor-concent" }, [
                      _vm._v(_vm._s(_vm.houseInfoList.areas) + "㎡")
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "settle-box" }, [
              _c("div", { staticClass: "bor", attrs: { border: "" } }, [
                _c("span", { staticClass: "title-header" }, [
                  _vm._v("设备报警信息")
                ])
              ]),
              _c("div", { staticClass: "bor", attrs: { border: "" } }, [
                _c("span", { staticClass: "bor-title" }, [_vm._v("设备编号")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.callThePoliceList.equipmentCode))
                ]),
                _c("span", { staticClass: "bor-title" }, [_vm._v("设备类型")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.callThePoliceList.productTypeName))
                ])
              ]),
              _c("div", { staticClass: "bor", attrs: { border: "" } }, [
                _c("span", { staticClass: "bor-title" }, [_vm._v(" 设备名称")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.callThePoliceList.productName))
                ]),
                _c("span", { staticClass: "bor-title" }, [_vm._v(" 安装房间")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.callThePoliceList.userRoomName))
                ])
              ]),
              _c("div", { staticClass: "bor", attrs: { border: "" } }, [
                _c("span", { staticClass: "bor-title" }, [_vm._v(" 报警类型")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.callThePoliceList.alarmTypeName))
                ]),
                _c("span", { staticClass: "bor-title" }, [_vm._v(" 报警信息")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.callThePoliceList.alarmMess))
                ])
              ]),
              _c("div", { staticClass: "bor", attrs: { border: "" } }, [
                _c("span", { staticClass: "bor-title" }, [_vm._v("报警地址")]),
                _c(
                  "span",
                  { staticClass: "bor-content-all" },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.callThePoliceList.address
                          ? _vm.callThePoliceList.address
                          : "无"
                      ) + " "
                    ),
                    _vm.callThePoliceList.address
                      ? _c(
                          "el-button",
                          {
                            staticStyle: {
                              "text-align": "right",
                              float: "right",
                              cursor: "pointer",
                              "padding-top": "5px",
                              "padding-bottom": "6px"
                            },
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.goAmap()
                              }
                            }
                          },
                          [_vm._v("查看地图")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "bor" }, [
                _c("span", { staticClass: "bor-title" }, [_vm._v("处理状态")]),
                _vm.callThePoliceList.handleStateName == "已处理"
                  ? _c("span", { staticClass: "bor-concent" }, [
                      _vm._v(_vm._s(_vm.callThePoliceList.handleStateName))
                    ])
                  : _vm._e(),
                _vm.callThePoliceList.handleStateName == "未处理"
                  ? _c(
                      "span",
                      {
                        staticClass: "bor-concent",
                        staticStyle: { color: "red" }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.callThePoliceList.handleStateName) + " "
                        ),
                        _vm.callThePoliceList.handleStateName == "未处理"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  float: "right",
                                  cursor: "pointer",
                                  "padding-top": "5px",
                                  "padding-bottom": "6px"
                                },
                                attrs: { type: "danger" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.handle()
                                  }
                                }
                              },
                              [_vm._v("处理")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _c("span", { staticClass: "bor-title" }, [_vm._v("报警时间")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(
                    _vm._s(
                      _vm.callThePoliceList.createTime ||
                        _vm.callThePoliceList.alarmTime
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "bor" }, [
                _c("span", { staticClass: "bor-title" }, [_vm._v("处理人")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.callThePoliceList.handler))
                ]),
                _c("span", { staticClass: "bor-title" }, [_vm._v("处理时间")]),
                _c("span", { staticClass: "bor-concent" }, [
                  _vm._v(_vm._s(_vm.callThePoliceList.handleTime))
                ])
              ]),
              _c("div", { staticClass: "bor", attrs: { border: "" } }, [
                _c("span", { staticClass: "bor-title" }, [_vm._v("处理情况")]),
                _c("span", { staticClass: "bor-content-all" }, [
                  _vm._v(
                    _vm._s(
                      _vm.callThePoliceList.handleContent
                        ? _vm.callThePoliceList.handleContent
                        : "无"
                    )
                  )
                ])
              ])
            ])
          ]),
          _c(
            "el-button",
            { staticClass: "returnClass", on: { click: _vm.goList } },
            [_vm._v("返回")]
          )
        ],
        1
      ),
      _c("treatment", {
        attrs: { isShowTreatment: _vm.isShowTreatment },
        on: {
          closeTreatment: _vm.closeTreatment,
          confirmTreatment: _vm.confirmTreatment
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }