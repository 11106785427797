import "core-js/modules/es.function.name";
import _objectSpread from "/mnt/f/project/javascriptproject/heath-alarm-provider/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import util from "@/libs/util";
import { mapState, mapActions } from "vuex";
export default {
  data: function data() {
    return {
      name: ""
    };
  },
  computed: _objectSpread({}, mapState("d2admin/user", ["info"])),
  created: function created() {
    this.name = util.cookies.get("name");
    console.log(util.cookies.get("yanglaoToken"), "养老token");
  },
  methods: _objectSpread(_objectSpread({}, mapActions("d2admin/account", ["logout"])), {}, {
    cs: function cs() {
      util.cookies.set("isGoIndex", 2);
      this.$router.push({
        path: "index"
      });
    },
    csb: function csb() {
      window.open("http://yanglao.isonnhe.com/#/auth?token=" + util.cookies.get("yanglaoToken"), "_self");
    },

    /**
     * @description 登出
     */
    logOff: function logOff() {
      this.logout({
        confirm: true
      });
    },
    tc: function tc() {
      this.logOff();
    }
  })
};