import _objectSpread from "/mnt/f/project/javascriptproject/heath-alarm-provider/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from 'vuex';
export default {
  computed: _objectSpread({}, mapState('d2admin/fullscreen', ['active'])),
  methods: _objectSpread({}, mapActions('d2admin/fullscreen', ['toggle']))
};