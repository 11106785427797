var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-login" }, [
    _c("div", { staticClass: "page-login--layer page-login--layer-area" }),
    _c("div", { staticClass: "page-login--layer" }, [
      _c(
        "div",
        {
          staticClass: "page-login--content",
          attrs: { flex: "dir:top main:justify cross:stretch box:justify" }
        },
        [
          _c("div", { staticClass: "page-login--content-header" }),
          _c(
            "div",
            {
              staticClass: "page-login--content-main",
              attrs: { flex: "dir:top main:center cross:center" }
            },
            [
              _c("div", { staticClass: "login_box" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "login" },
                  [
                    _vm._m(1),
                    _c("div", { staticClass: "login_name" }, [
                      _c("p", [_vm._v(_vm._s(_vm.title))])
                    ]),
                    _c(
                      "el-form",
                      {
                        ref: "loginForm",
                        attrs: {
                          "label-position": "top",
                          rules: _vm.rules,
                          model: _vm.formLogin,
                          size: "default"
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "username" } },
                          [
                            _c("img", {
                              staticStyle: {
                                float: "left",
                                "margin-top": "10px"
                              },
                              attrs: { src: require("../login/image/hy.png") }
                            }),
                            _c("el-input", {
                              staticStyle: { width: "80%" },
                              attrs: { type: "text", placeholder: "用户名" },
                              model: {
                                value: _vm.formLogin.username,
                                callback: function($$v) {
                                  _vm.$set(_vm.formLogin, "username", $$v)
                                },
                                expression: "formLogin.username"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "password" } },
                          [
                            _c("img", {
                              staticStyle: {
                                float: "left",
                                "margin-top": "10px"
                              },
                              attrs: { src: require("../login/image/suo.png") }
                            }),
                            _c("el-input", {
                              staticStyle: { width: "80%" },
                              attrs: { type: "password", placeholder: "密码" },
                              model: {
                                value: _vm.formLogin.password,
                                callback: function($$v) {
                                  _vm.$set(_vm.formLogin, "password", $$v)
                                },
                                expression: "formLogin.password"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "button-login",
                            attrs: { size: "default", type: "primary" },
                            on: { click: _vm.submit }
                          },
                          [_vm._v(" 登录 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]
          ),
          _vm._m(2)
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login_l_img" }, [
      _c("img", { attrs: { src: require("../login/image/login-img.png") } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login_logo" }, [
      _c("a", { attrs: { href: "#" } }, [
        _c("img", { attrs: { src: require("../login/image/login_logo.png") } })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-login--content-footer" }, [
      _c("p", { staticClass: "page-login--content-footer-options" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }