var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "top" }, [
      _vm._m(0),
      _c("div", { staticClass: "top-right" }, [
        _c("div", [_vm._v(" " + _vm._s(_vm.name))]),
        _c(
          "div",
          {
            staticClass: "tc",
            on: {
              click: function($event) {
                return _vm.tc()
              }
            }
          },
          [_vm._v("退出")]
        )
      ])
    ]),
    _c("div", [
      _c("div", { staticClass: "information" }, [
        _vm._m(1),
        _c("div", { staticClass: "name" }, [
          _c("div", { staticStyle: { "font-size": "20px" } }, [
            _vm._v(" 你好，" + _vm._s(_vm.name) + " ")
          ]),
          _c("div", { staticClass: "yy" }, [_vm._v("运营方：北京声赫")])
        ])
      ])
    ]),
    _c("div", { staticClass: "nr" }, [
      _c("div", { staticClass: "bj", on: { click: _vm.cs } }, [_vm._m(2)]),
      _c("div", { staticClass: "fw", on: { click: _vm.csb } }, [_vm._m(3)])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-name" }, [
      _c("div", { staticClass: "tu", staticStyle: { "margin-top": "10px" } }, [
        _c("img", {
          attrs: { src: require("../../../../public/icon.ico.jpg"), alt: "" }
        })
      ]),
      _c("div", { staticStyle: { "margin-left": "10px" } }, [
        _vm._v("声赫智慧养老运营平台")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image" }, [
      _c("img", { attrs: { src: require("./tx.png"), alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ys" }, [
      _c(
        "div",
        {
          staticClass: "bq",
          staticStyle: { "font-size": "25px", color: "#1890ff" }
        },
        [_vm._v(" 养老 ")]
      ),
      _c("div", { staticStyle: { "margin-top": "20px" } }, [
        _vm._v(" 居家养老运营系统 ")
      ]),
      _c(
        "div",
        { staticStyle: { "font-size": "20px", "margin-top": "10px" } },
        [_vm._v(" 居家养老“人”“物”“场”规范化管理流程 ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ys" }, [
      _c(
        "div",
        {
          staticClass: "bq",
          staticStyle: { "font-size": "30px", color: "#ff8c00" }
        },
        [_vm._v(" 服务 ")]
      ),
      _c("div", { staticStyle: { "margin-top": "20px" } }, [
        _vm._v(" 居家养老服务系统 ")
      ]),
      _c(
        "div",
        { staticStyle: { "font-size": "20px", "margin-top": "10px" } },
        [_vm._v(" 打通居家养老服务最后一公里 ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }