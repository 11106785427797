import "core-js/modules/es.array.concat";
import _objectSpread from "/mnt/f/project/javascriptproject/heath-alarm-provider/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import d2MenuSide from "./components/menu-side";
import d2Tabs from "./components/tabs";
import d2HeaderFullscreen from "./components/header-fullscreen"; // import d2HeaderSize from './components/header-size'

import d2HeaderUser from "./components/header-user";
import { mapState, mapGetters, mapActions } from "vuex";
import mixinSearch from "./mixins/search";
export default {
  name: "d2-layout-header-aside",
  mixins: [mixinSearch],
  components: {
    // d2HeaderSize,
    d2MenuSide: d2MenuSide,
    d2Tabs: d2Tabs,
    d2HeaderFullscreen: d2HeaderFullscreen,
    d2HeaderUser: d2HeaderUser
  },
  data: function data() {
    return {
      // [侧边栏宽度] 正常状态
      asideWidth: "200px",
      // [侧边栏宽度] 折叠状态
      asideWidthCollapse: "65px",
      title: ""
    };
  },
  created: function created() {
    this.title = process.env.VUE_APP_TITLE;
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState("d2admin", {
    keepAlive: function keepAlive(state) {
      return state.page.keepAlive;
    },
    grayActive: function grayActive(state) {
      return state.gray.active;
    },
    transitionActive: function transitionActive(state) {
      return state.transition.active;
    },
    asideCollapse: function asideCollapse(state) {
      return state.menu.asideCollapse;
    },
    asideTransition: function asideTransition(state) {
      return state.menu.asideTransition;
    }
  })), mapGetters("d2admin", {
    themeActiveSetting: "theme/activeSetting"
  })), {}, {
    /**
     * @description 用来实现带参路由的缓存
     */
    routerViewKey: function routerViewKey() {
      // 默认情况下 key 类似 __transition-n-/foo
      // 这里的字符串操作是为了最终 key 的格式和原来相同 类似 __transition-n-__stamp-time-/foo
      var stamp = this.$route.meta["__stamp-".concat(this.$route.path)] || "";
      return "".concat(stamp ? "__stamp-".concat(stamp, "-") : "").concat(this.$route.path);
    },

    /**
     * @description 最外层容器的背景图片样式
     */
    styleLayoutMainGroup: function styleLayoutMainGroup() {
      return this.themeActiveSetting.backgroundImage ? {
        backgroundImage: "url('".concat(this.$baseUrl).concat(this.themeActiveSetting.backgroundImage, "')")
      } : {};
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions("d2admin/menu", ["asideCollapseToggle"])), {}, {
    /**
     * 接收点击切换侧边栏的按钮
     */
    handleToggleAside: function handleToggleAside() {
      this.asideCollapseToggle();
    }
  })
};