var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c(
      "div",
      { staticClass: "providerOrderList-container" },
      [
        _c("div", { staticClass: "filter-box" }, [
          _c(
            "div",
            { staticClass: "filter-box-name" },
            [
              _c(
                "el-descriptions",
                {
                  staticClass: "margin-top",
                  attrs: {
                    title: "用户信息",
                    column: 2,
                    border: "",
                    "cell-style": { textAlign: "center" }
                  }
                },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { labelStyle: { width: "160px" } } },
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 用户姓名 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.details.userName) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { labelStyle: { width: "160px" } } },
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 手机号 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.details.userPhone) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 用户类型 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.details.userTypeName) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 身份证号 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.details.idCode) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 性别 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.details.sexName) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 年龄 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.details.age) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 居住房间 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.details.userRoomName) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 监护人数 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.details.guardianNumber) + " ")
                    ],
                    2
                  ),
                  _c("el-descriptions-item", { attrs: { span: 2 } }),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 房屋信息 ")
                      ])
                    ],
                    2
                  ),
                  _c("el-descriptions-item"),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 所在地区 ")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.houseUserInfo.provinceNameName ||
                              _vm.houseUserInfo.provinceName
                          ) +
                          " "
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 所在区（县） ")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.houseUserInfo.streetName
                              ? _vm.houseUserInfo.areaName
                              : _vm.houseUserInfo.cityName
                          ) +
                          " "
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 街道（镇） ")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.houseUserInfo.streetName ||
                              _vm.houseUserInfo.areaName
                          ) +
                          " "
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 社区（村） ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.houseUserInfo.community) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 2 }, slot: "label" }, [
                        _vm._v(" 详细地址 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.houseUserInfo.address) + " ")
                    ],
                    2
                  ),
                  _c("el-descriptions-item"),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 所在小区 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.houseUserInfo.districtName) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 楼栋号 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.houseUserInfo.floorNumber) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 单元号 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.houseUserInfo.unitNumber) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 门牌号 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.houseUserInfo.doorNumber) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 房屋户型 ")
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.houseUserInfo.houseTypeName) + " "
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { attrs: { span: 6 }, slot: "label" }, [
                        _vm._v(" 面积 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.houseUserInfo.areas) + " ")
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _c(
          "div",
          [
            _c("div", { staticClass: "filter-btn" }, [
              _c("span", [_vm._v("监护人信息")])
            ]),
            _c(
              "el-table",
              {
                key: _vm.tablekey,
                staticStyle: { width: "100%" },
                attrs: { data: _vm.graByInsUserId, size: "medium" }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "id", label: "监护人ID" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "userName", label: "姓名" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "userPhone", label: "手机号" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "relationship", label: "关系" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "idCode", label: "身份证号" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "sexName", label: "性别" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "age", label: "年龄" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "isDeletedName", label: "启用状态" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "createTime", label: "创建时间" }
                }),
                _c("el-table-column", {
                  attrs: { fixed: "right", label: "操作", width: "200" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "danger", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.effectiveControlGuardian(
                                    scope.row.id
                                  )
                                }
                              }
                            },
                            [_vm._v("移除")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _c("addDevice", {
          attrs: { isShowAddDevice: _vm.isShowAddDevice },
          on: {
            confirmAddDevice: _vm.confirmAddDevice,
            closeAddDevice: _vm.closeAddDevice
          }
        }),
        _c("addGuardian", {
          attrs: { isShowGuardian: _vm.isShowGuardian },
          on: {
            confirmAddUser: _vm.confirmAddUser,
            cancelAddUser: _vm.cancelAddUser
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }