import "core-js/modules/es.array.for-each";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.for-each";
import "core-js/modules/web.dom-collections.iterator";

/**
 * The file enables `@/store/index.js` to import all vuex modules
 * in a one-shot manner. There should not be any reason to edit this file.
 */
var files = require.context('./modules', false, /\.js$/);

var modules = {};
files.keys().forEach(function (key) {
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default;
});
export default {
  namespaced: true,
  modules: modules
};